import { Location } from '@zupr/types/fo';
import { SiteProps } from '@zupr/types/next';
import { locationSchema } from '@zupr/utils/schema';
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base';
import Providers from '@zupr/web/src/js/frontoffice/providers';
import { LocationInfo } from '@zupr/web/src/js/frontoffice/screens/location';
import { GetServerSideProps } from 'next';

import { inShoppingArea, redirectToShoppingArea } from '.';
import Schema from '../../../components/schema';
import { getLocation, locationMeta } from '../../../helpers/location';
import { getProps } from '../../../helpers/props';

interface PageProps extends SiteProps {
    location: Location
    schema: Awaited<ReturnType<typeof locationSchema>>
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({ req, params, query }) => {
    const siteProps = await getProps({ req, params, query })

    const location = await getLocation({ locationId: params.locationId as string,   ...siteProps }, req)

    if (!location) {
        return {
            notFound: true,
        }
    }

    if (!inShoppingArea(location, siteProps)) {
        return await redirectToShoppingArea(location, req)
    }

    const meta = locationMeta({ location, ...siteProps })
    const schema = locationSchema(location)
    return {
        props: {
            ...siteProps,
            schema,
            meta,
            location,
        },
    }
}

const Index = ({ location, schema, ...props }: PageProps) => (
    <Providers {...props}>
        <Frontoffice>
            <LocationInfo location={location} />
        </Frontoffice>
        <Schema schema={schema} id={location.id} />
    </Providers>
)

export default Index
